<template>
  <EntitySingle :config="config" :entity.sync="entity" :loaded.sync="loaded" :extra-validation="extraValidation"
    :extra-actions="extraActions">
    <b-card v-if="loaded">
      <b-tabs v-model="activeTabIndex" pills>
        <!-- Tab: Account -->
        <b-tab v-if="!editPriceOnly" active>
          <template #title>
            <feather-icon icon="ArchiveIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
          </template>
          <EntityEditSection :module="config.module" :entity.sync="entity" :form-fields="infoFields" allow-autofocus />
        </b-tab>

        <!-- Tab: Inner Page -->
        <b-tab v-if="!editPriceOnly">
          <template #title>
            <feather-icon icon="LayoutIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Layout') }}</span>
          </template>
          <EntityEditSection :module="config.module" :entity.sync="entity" :form-fields="layoutFields.header" />
          <LayoutPreview layout="product" />
        </b-tab>

        <!-- Tab: Categories -->
        <b-tab v-if="!editPriceOnly">
          <template #title>
            <feather-icon icon="ArchiveIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Categories') }}</span>
          </template>
          <EntityEditSection :module="config.module" :entity.sync="entity" :form-fields="categoryFormFields"
            allow-autofocus />
        </b-tab>
        <!-- Tab: product story -->

        <b-tab v-if="!editPriceOnly">
          <template #title>
            <feather-icon icon="LayoutIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Product Story') }}</span>
          </template>
          <template v-if="!editPriceOnly && loaded">
            <ProductLayoutEdit :title="$t('ProductStoryZone1')" :module="config.module" :entity.sync="entity"
              :form-fields="productStoryField.zone1" required-section />
            <ProductLayoutEdit :title="$t('ProductStoryZone2')" :module="config.module" :entity.sync="entity"
              :form-fields="productStoryField.zone2" required-section />
            <ProductLayoutEdit :title="$t('ProductStoryZone3')" :module="config.module" :entity.sync="entity"
              :form-fields="productStoryField.zone3" />
            <ProductLayoutEdit :module="config.module" :entity.sync="entity"
              :form-fields="productStoryField.zone4" />
            <ProductLayoutEdit :module="config.module" :entity.sync="entity" :form-fields="productStoryField.footer" />
            <ProductLayoutEdit :module="config.module" :entity.sync="entity" :form-fields="productStoryField.zone5" />
            <div class="d-flex align-center justify-content-start mb-1">
              <div class="section-image">
                <img src="@/assets/images/icons/section-valid.png" />
              </div>

              <p class="ml-1">
                {{ $t('ZoneComplete') }}
              </p>
            </div>

            <div class="d-flex align-center justify-content-start">
              <div class="section-image">
                <img src="@/assets/images/icons/section-invalid.png" />
              </div>

              <p class="ml-1" v-html="$t('ZonePending')" />
            </div>
          </template>
        </b-tab>
        <!-- Tab: product story -->
        <b-tab v-if="!editPriceOnly">
          <template #title>
            <feather-icon icon="LayoutIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Brand Story') }}</span>
          </template>
          <template v-if="!editPriceOnly && loaded">
            <ProductLayoutEdit :title="$t('BrandStoryZone1')" :module="config.module" :entity.sync="entity"
              :form-fields="brandStoryField.zone1" required-section />
            <ProductLayoutEdit :title="$t('BrandStoryZone2')" :module="config.module" :entity.sync="entity"
              :form-fields="brandStoryField.zone2" required-section />
            <ProductLayoutEdit :title="$t('BrandStoryZone3')" :module="config.module" :entity.sync="entity"
              :form-fields="brandStoryField.zone3" />
            <div class="d-flex align-center jZone3ustify-content-start mb-1">
              <div class="section-image">
                <img src="@/assets/images/icons/section-valid.png" />
              </div>

              <p class="ml-1">
                {{ $t('ZoneComplete') }}
              </p>
            </div>

            <div class="d-flex align-center justify-content-start">
              <div class="section-image">
                <img src="@/assets/images/icons/section-invalid.png" />
              </div>

              <p class="ml-1" v-html="$t('ZonePending')" />
            </div>
          </template>
        </b-tab>

        <!-- Tab : Free Bonus  -->
        <b-tab v-if="!editPriceOnly">
          <template #title>
            <feather-icon icon="LayoutIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Free Bonus') }}</span>
          </template>
          <template v-if="!editPriceOnly && loaded">
          <ProductLayoutEdit :title="$t('Free Bonus Data')" :module="config.module" :entity.sync="entity"
            :form-fields="freeBonusField.zone1" />
          <ProductLayoutEdit :module="config.module" :entity.sync="entity" :form-fields="freeBonusField.footer" />
          <div class="d-flex align-center justify-content-start mb-1">
            <div class="section-image">
              <img src="@/assets/images/icons/section-valid.png" />
            </div>

            <p class="ml-1">
              {{ $t('ZoneComplete') }}
            </p>
          </div>

          <div class="d-flex align-center justify-content-start">
            <div class="section-image">
              <img src="@/assets/images/icons/section-invalid.png" />
            </div>

            <p class="ml-1" v-html="$t('ZonePending')" />
          </div>
        </template>
        </b-tab>
        <!-- Tab : Free Bonus  -->

   
        <!-- Tab: Options -->
        <b-tab v-if="false && (entity.id > 0 || isDuplicate)">
          <template #title>
            <feather-icon icon="PackageIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Options') }}</span>
          </template>
          <b-row>
            <b-col cols="12">
              <RepeaterField :module="config.module" :title="$t('Option')" :field="entity.productOptions"
                :form-fields="optionFormFields" />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>
  
    <!-- Layout Tab -->
    <template v-if="!editPriceOnly && loaded && activeTabIndex === 1">
      <ProductLayoutEdit :title="$t('Zone1')" :module="config.module" :entity.sync="entity"
        :form-fields="layoutFields.zone1" :multi-grid-columns="[6, 6]" required-section use-multi-grid />
      <ProductLayoutEdit :title="$t('Zone2')" :module="config.module" :entity.sync="entity"
        :form-fields="layoutFields.zone2" />
      <ProductLayoutEdit :title="$t('Zone3')" :module="config.module" :entity.sync="entity" :form-fields="layoutFields.zone3" />
      <div class="d-flex align-center justify-content-start mb-1">
        <div class="section-image">
          <img src="@/assets/images/icons/section-valid.png" />
        </div>

        <p class="ml-1">
          {{ $t('ZoneComplete') }}
        </p>
      </div>

      <div class="d-flex align-center justify-content-start">
        <div class="section-image">
          <img src="@/assets/images/icons/section-invalid.png" />
        </div>

        <p class="ml-1" v-html="$t('ZonePending')" />
      </div>
    </template>
  </EntitySingle>
</template>

<script>
import { BRow, BCol, BTab, BTabs, BCard, BAvatar } from 'bootstrap-vue'

import {
  getUserRights,
  isAdmin,
  isSeller,
  isSupplier,
  canEditPrice,
  canPublishToShop,
  canUnPublishFromShop,
} from '@/auth/utils'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import RepeaterField from '@/layouts/entity/RepeaterField.vue'
import LayoutPreview from '@/layouts/entity/LayoutPreview.vue'
import ProductLayoutEdit from '@/components/ProductLayout/ProductLayoutEdit.vue'
import ProductPricesList from './ProductPricesList.vue'
import axios from '@/libs/axios'

import storeModule from '../dealsStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAvatar,
    EntitySingle,
    EntityEditSection,
    ProductLayoutEdit,
    LayoutPreview,
    RepeaterField,
    ProductPricesList,
  },
  data() {
    return {
      activeTabIndex: 0,
      entity: {
        productPrice: {},
        productOptions: [],
        productGalleries: [],
        category: '',
        subCategory: '',
        deal: true,
        enabled:true
      },
      loaded: false,
      // categoryOptions: [],
      styleOptions: [{ id: true, title: 'Yes' }, { id: false, title: 'No' }],
      statusOptions: [{ id: 'Active', title: 'Active' }, { id: 'Discontinued', title: 'Discontinued' },{ id: 'Coming Soon', title: 'Coming soon' }],
      dealOptions: [{ id: 'Seller', title: 'Store' }, { id: 'Supplier', title: 'Manufacturer' }],
      sellerOptions: [],
      supplierOptions: [],
      colorOptions: [],
      tagsOptions: [],
      shippingClassOptions: [],
      categoryListOptions: [],
      subCategoryOptions: [],
      attributeOptions: [],
      subAttributeOptions: {},
      initialLoadCategory: true,
      initialLoadSubCategory: true,
    }
  },
  computed: {
    isDuplicate() {
      return this.$route.params.duplicate
    },
    rights() {
      return getUserRights()
    },
    isAdmin() {
      return isAdmin()
    },
    isSupplier() {
      return isSupplier()
    },
    isSeller() {
      return isSeller()
    },
    canEditPrice() {
      return canEditPrice()
    },
    editPriceOnly() {
      return isSeller() && this.entity.supplier
    },
    entityProdCategory() {
      return this.entity.category && this.entity.category.id
    },
    entityProdSubCategory() {
      return this.entity.subCategory && this.entity.subCategory.id
    },

    config() {
      return {
        store: storeModule,
        module: 'products/deals',
        endpoint: 'products/deals',
        route: 'deals',
        title: {
          single: this.$t('deal'),
          plural: this.$t('deals'),
        },
      }
    },
    supplierLogoFields() {
      return [
        {
          id: 'logo',
          type: 'cropupload',
          label: `${this.$t('Logo')} (512x512)`,
          colSize: 6,
          width: 512,
          height: 512,
          autoupload: true,
          required: false,
        },
      ]
    },
    infoFields() {
      const fields = [
        {
          id: 'eshopId',
          type: 'hidden',
          hidden: true,
        },
        {
          id: 'featuredImg',
          type: 'cropupload',
          label: `${this.$t('Featured Image')} A (1200x1200)`,
          colSize: 6,
          width: 1200,
          height: 1200,
          autoupload: true,
          required: true,
        },
        {
          id: 'featuredImgB',
          type: 'cropupload',
          label: `${this.$t('Featured Image')} B (1200x1200)`,
          colSize: 6,
          width: 1200,
          height: 1200,
          autoupload: true,
          required: true,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('Deal Product Name'),
          required: true,
          colSize: 4,
        },
        {
          id: 'code',
          type: 'text',
          label: this.$t('Deal Product Code'),
          required: true,
          colSize: 3,
        },
        {
          id: 'dealEndDate',
          type: 'datetime',
          label: this.$t('Deal End Date'),
          colSize: 3,
        },
        {
          id: 'regularPrice',
          parent:'productPrice',
          type: 'text',
          label: this.$t('Actual price'),
          rules: 'positive',
          colSize: 3,
          readonly: !this.isSupplier && !this.isAdmin,
        },
        {
          id: 'salePrice',
          parent:'productPrice',
          type: 'text',
          label: this.$t('Discount price'),
          rules: 'positive',
          required: true,
          colSize: 3,
          readonly: !this.isSupplier && !this.isAdmin,
        },
        {
          id: 'sharePrice',
          parent:'productPrice',
          type: 'text',
          label: this.$t('Share price'),
          rules: 'positive',
          required: true,
          colSize: 3,
          readonly: !this.isSupplier && !this.isAdmin,
        },
        {
          id: 'productColors',
          object: true,
          type: 'select',
          label: this.$t('Color'),
          options: this.colorOptions,
          required: false,
          colSize: 3,
        },
        {
          id: 'productStatus',
          type: 'select',
          label: this.$t('Status'),
          options: this.statusOptions,
          required:true,
          colSize: 2,
        },{
          id: 'length',
          type: 'text',
          label: this.$t('length'),
          rules: 'positive',
          colSize: 2,
        },
        {
          id: 'width',
          type: 'text',
          label: this.$t('width'),
          rules: 'positive',
          colSize: 2,
        },
        {
          id: 'height',
          type: 'text',
          label: this.$t('height'),
          rules: 'positive',
          colSize: 2,
        },
        {
          id: 'weight',
          type: 'text',
          label: this.$t('weight'),
          rules: 'positive',
          colSize: 2,
        }, 
        {
          id: 'hasStock',
          type: 'checkbox',
          label: this.$t('Has Stock'),
          colSize: 1,
        },
        {
          id: 'quantity',
          type: 'text',
          label: this.$t('quantity'),
          required: false,
          rules: 'integer',
          colSize: 1,
          dependency: 'hasStock',
        },
        {
          id: 'refurbished',
          type: 'checkbox',
          label: this.$t('Refurbished'),
          colSize: 1,
        },
        {
          id: 'customized',
          type: 'checkbox',
          label: this.$t('Customization'),
          colSize: 1,
        },
        {
          id: 'customColor',
          type: 'select',
          label: this.$t('Color'),
          options: this.styleOptions,
          colSize: 2,
          dependency: 'customized'
        },
        {
          id: 'customFabric',
          type: 'select',
          label: this.$t('Fabric'),
          options: this.styleOptions,
          colSize: 2,
          dependency: 'customized'

        },
        {
          id: 'customDimension',
          type: 'select',
          label: this.$t('Dimension'),
          options: this.styleOptions,
          colSize: 2,
          dependency: 'customized'

        },
      ]
      if (this.isAdmin) {
        fields.push({
          id: 'dealProvider',
          type: 'select',
          label: this.$t('Deal Provider'),
          options: this.dealOptions,
          colSize: 2,
          required:true,
        })
      }
      if (this.isAdmin && this.entity?.dealProvider === 'Seller') {
        fields.push({
          id: 'seller',
           parent: 'productPrice',
          type: 'select',
          object: true,
          label: this.$t('Store'),
          options: this.sellerOptions,
          colSize: 4,
        })
      }
      if (this.isAdmin && this.entity?.dealProvider === 'Supplier') {
        fields.push({
          id: 'supplier',
          type: 'select',
          object: true,
          label: this.$t('Manufacturer'),
          options: this.supplierOptions,
          colSize: 4,
        })
      }
      
      return fields
    },

    categoryFormFields() {
      const categoryfields = [
        {
          id: 'category',
          type: 'select',
          object: true,
          label: this.$t('product category'),
          options: this.categoryListOptions,
          required: true,
          colSize: 6,
        },
        {
          id: 'subCategory',
          type: 'select',
          object: true,
          label: this.$t('productSubCategory'),
          options: this.subCategoryOptions,
          colSize: 6,
        },
      ]

      this.attributeOptions.forEach((item) => {
        categoryfields.push({
          id: item.id,
          type: 'selectmultiple',
          object: true,
          label: item.title,
          options: this.subAttributeOptions[item.id],
          dependency: 'subCategory',
          colSize: 3,
        })
      })
      return categoryfields
    },

    layoutFields() {
      return {
        header: [
        {
            id: 'generalDescription',
            type: 'quill',
            limitedOptions: true,
            label: this.$t('generalDescription'),
            required:true,
            colSize: 12,
          },
        ],
        zone1: [
          [
            {
              id: 'image1',
              type: 'cropupload',
              label: `${this.$t('Image')} #1: (1200x1200)`,
              colSize: 12,
              width: 1200,
              height: 1200,
              autoupload: true,
            },
            {
              id: 'image2',
              type: 'cropupload',
              label: `${this.$t('Image')} #2: (1200x1200)`,
              colSize: 12,
              width: 1200,
              height: 1200,
              autoupload: true,
            },
          ],
          [
            {
              id: 'image3',
              type: 'cropupload',
              label: `${this.$t('Image')} #3: (1200x1200)`,
              colSize: 12,
              width: 1200,
              height: 1200,
              autoupload: true,
            },
            {
              id: 'image4',
              type: 'cropupload',
              label: `${this.$t('Image')} #4: (1200x1200)`,
              colSize: 12,
              width: 1200,
              height: 1200,
              autoupload: true,
            },
          ],
        ],
        zone2: [
          {
            id: 'image5',
            type: 'cropupload',
            label: `${this.$t('Image')} #5: (1200x1200)`,
            colSize: 6,
            width: 1200,
            height: 1200,
            autoupload: true,
          },
          {
            id: 'image6',
            type: 'cropupload',
            label: `${this.$t('Image')} #6: (1200x1200)`,
            colSize: 6,
            width: 1200,
            height: 1200,
            autoupload: true,
          },
          {
            id: 'dimensionImage',
            type: 'cropupload',
            label: `${this.$t('Product Dimension')}: (750x365)`,
            colSize: 12,
            width: 750,
            height: 365,
            autoupload: true,
          },
        ],
        zone3: [
          {
            id: 'videoUrl',
            type: 'text',
            label: `${this.$t('Product Video URL')}`,
            colSize: 12,
          },{
            id: 'videoThumbnailUrl',
            type: 'cropupload',
            label: `${this.$t('Video Preview')}: (750x365)`,
            // layoutPreview: 'product_image_4',
            colSize: 12,
            width: 750,
            height: 365,
            autoupload: true,
          },
        ],
      }
    },
    productStoryField() {
      return {
        zone1: [
          {
            id: 'productStoryHeaderZone1',
            type: 'quill',
            limitedOptions: true,
            label: this.$t('shortDescription'),
            colSize: 12,
          },
        ],
        zone2: [
          {
            id: 'productStoryZone2Image',
            type: 'cropupload',
            label: `${this.$t('Image')} #1: (675x365)`,
            // layoutPreview: 'product_story_image_1',
            colSize: 7,
            width: 675,
            height: 365,
            autoupload: true,
          },
          {
            id: 'productStoryZone2',
            type: 'quill',
            limitedOptions: true,
            label: `${this.$t('Content')} #1`,
            // layoutPreview: 'product_story_content_1',
            colSize: 5
          },
        ],
        zone3: [

          {
            id: 'productStoryZone3',
            type: 'quill',
            limitedOptions: true,
            label: `${this.$t('Content')} #2`,
            // layoutPreview: 'product_story_content_2',
            colSize: 5
          },
          {
            id: 'productStoryZone3Image',
            type: 'cropupload',
            label: `${this.$t('Image')} #2: (675x365)`,
            // layoutPreview: 'product_story_image_2',
            colSize: 7,
            width: 675,
            height: 365,
            autoupload: true,
          },
        ],
        zone4: [
          {
            id: 'productStoryZone4Image',
            type: 'cropupload',
            label: `${this.$t('Image')} #3: (675x365)`,
            // layoutPreview: 'product_story_image_3',
            colSize: 7,
            width: 675,
            height: 365,
            autoupload: true,
          },
          {
            id: 'productStoryZone4',
            type: 'quill',
            limitedOptions: true,
            label: `${this.$t('Content')} #3`,
            // layoutPreview: 'product_story_content_3',
            colSize: 5
          },
        ],
        zone5: [

          {
            id: 'productStoryZone5Image1',
            type: 'cropupload',
            label: `${this.$t('Image')} #4: (366x406)`,
            // layoutPreview: 'product_story_image_4',
            colSize: 4,
            width: 366,
            height: 406,
            autoupload: true,
          },
          {
            id: 'productStoryZone5Image2',
            type: 'cropupload',
            label: `${this.$t('Image')} #5: (366x406)`,
            // layoutPreview: 'product_story_image_5',
            colSize: 4,
            width: 366,
            height: 406,
            autoupload: true,
          },
          {
            id: 'productStoryZone5Image3',
            type: 'cropupload',
            label: `${this.$t('Image')} #6: (366x406)`,
            // layoutPreview: 'product_story_image_6',
            colSize: 4,
            width: 366,
            height: 406,
            autoupload: true,
          }, {
            id: 'productStoryZone5Image4',
            type: 'cropupload',
            label: `${this.$t('Image')} #7: (366x406)`,
            // layoutPreview: 'product_story_image_7',
            colSize: 4,
            width: 366,
            height: 406,
            autoupload: true,
          }, {
            id: 'productStoryZone5Image5',
            type: 'cropupload',
            label: `${this.$t('Image')} #8: (366x406)`,
            // layoutPreview: 'product_story_image_8',
            colSize: 4,
            width: 366,
            height: 406,
            autoupload: true,
          }, {
            id: 'productStoryZone5Image6',
            type: 'cropupload',
            label: `${this.$t('Image')} #9: (366x406)`,
            // layoutPreview: 'product_story_image_9',
            colSize: 4,
            width: 366,
            height: 406,
            autoupload: true,
          }
        ],
        footer: [
          {
            id: 'productStoryZone5',
            type: 'quill',
            limitedOptions: true,
            label: this.$t('generalDescription'),
            // layoutPreview: 'product_general_description',
            colSize: 8,
          },
          {
          id: 'iconUrl',
          type: 'cropupload',
          label: `${this.$t('Product story icon')} (512x512)`,
          colSize: 3,
          width: 512,
          height: 512,
          autoupload: true,
          required: true,
        },
        ],
      }
    },
    brandStoryField() {
      return {
        zone1: [
          {
            id: 'brandStoryZone1Image',
            type: 'cropupload',
            label: `${this.$t('Image')} #1: (1440x600)`,
            colSize: 12,
            width: 1440,
            height: 600,
            autoupload: true,
          },
          {
            id: 'brandStoryZone1',
            type: 'quill',
            limitedOptions: true,
            label: `${this.$t('Content')} #1`,
            colSize: 10
          },
        ],
        zone2: [
          {
            id: 'brandStoryZone2Image',
            type: 'cropupload',
            label: `${this.$t('Image')} #2: (675x365)`,
            colSize: 7,
            width: 675,
            height: 365,
            autoupload: true,
          },
          {
            id: 'brandStoryZone2',
            type: 'quill',
            limitedOptions: true,
            label: `${this.$t('Content')} #2`,
            colSize: 5
          },

        ],
        zone3: [
          {
            id: 'brandStoryZone3',
            type: 'quill',
            limitedOptions: true,
            label: `${this.$t('Content')} #3`,
            colSize: 5
          },
          {
            id: 'brandStoryZone3Image',
            type: 'cropupload',
            label: `${this.$t('Image')} #3: (675x365)`,
            colSize: 7,
            width: 675,
            height: 365,
            autoupload: true,
          },

        ],
      }
    },
    freeBonusField() {
      return {
        zone1: [
        {
            id: 'freeBonusBenefits',
            type: 'quill',
            limitedOptions: true,
            label: this.$t('Free Bonus Benefits'),
            colSize: 12,
          },
        {
          id: 'freeBonusEndDate',
          type: 'date',
          label: this.$t('Free Bonus End Date'),
          colSize: 3,
        },
      
        {
          id: 'totalBonusValue',
          type: 'text',
          label: this.$t('Total Bonus Value'),
          rules: 'positive',
          colSize: 3,
        },
        {
            id: 'freeBonusClaimText',
            type: 'quill',
            limitedOptions: true,
            label: this.$t('Free Bonus Claim text'),
            colSize: 8,
          },
        ],
        footer: [
          {
            id: 'freeBonusClaimTerms',
            type: 'quill',
            limitedOptions: true,
            label: this.$t('Free Bonus Terms'),
            colSize: 8,
          },
        ],
      }
    },
    galleryFormFields() {
      return [
        {
          id: 'img',
          type: 'image',
          label: this.$t('Featured Image'),
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('name'),
          required: true,
        },
      ]
    },

    optionFormFields() {
      return [
        {
          id: 'image',
          type: 'cropupload',
          label: `${this.$t('Image')}: (600x600)`,
          width: 600,
          height: 600,
          autoupload: true,
          colSize: 3,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('name'),
          required: true,
          colSize: 3,
        },
        {
          id: 'price',
          type: 'text',
          label: this.$t('price'),
          required: true,
          rules: 'positive',
          colSize: 2,
        },
        {
          id: 'salePrice',
          type: 'text',
          label: this.$t('sale price'),
          required: false,
          rules: 'positive',
          colSize: 2,
        },
      ]
    },

    priceFields() {
      return [
        {
          id: 'minPrice',
          type: 'text',
          label: this.$t('min price'),
          rules: 'positive',
          colSize: 4,
          readonly: true,
        },
        {
          id: 'maxPrice',
          type: 'text',
          label: this.$t('max price'),
          rules: 'positive',
          colSize: 4,
          readonly: true,
        },
        {
          id: 'targetPrice',
          type: 'text',
          label: this.$t('Target Price'),
          rules: 'positive',
          colSize: 4,
          readonly: true,
        },
        {
          id: 'regularPrice',
          parent: 'productPrice',
          type: 'text',
          label: this.$t('price'),
          required: true,
          rules: 'positive',
          colSize: 6,
          readonly: !this.canEditPrice,
        },
        {
          id: 'salePrice',
          parent: 'productPrice',
          type: 'text',
          label: this.$t('salesPrice'),
          rules: 'positive',
          colSize: 6,
          readonly: !this.canEditPrice,
        },
        {
          id: 'saleStartDate',
          parent: 'productPrice',
          type: 'date',
          label: this.$t('saleStartDate'),
          dependency: 'salePrice',
          refMaxDate: 'saleEndDate',
          colSize: 6,
          readonly: !this.canEditPrice,
        },
        {
          id: 'saleEndDate',
          parent: 'productPrice',
          type: 'date',
          label: this.$t('saleEndDate'),
          dependency: 'salePrice',
          refMinDate: 'saleStartDate',
          required: false,
          colSize: 6,
          readonly: !this.canEditPrice,
        },
        {
          id: 'deliveryReady',
          parent: 'productPrice',
          type: 'checkbox',
          label: this.$t('Ready for delivery'),
          colSize: 4,
          readonly: !this.canEditPrice,
        },
        {
          id: 'showroom',
          parent: 'productPrice',
          type: 'checkbox',
          label: this.$t('Showroom'),
          colSize: 4,
          readonly: !this.canEditPrice,
        },
        {
          id: 'constructionDays',
          parent: 'productPrice',
          type: 'text',
          label: this.$t('Shipping Lead Days'),
          rules: 'integer',
          colSize: 4,
        },
        {
          id: 'quantity',
          parent: 'productPrice',
          type: 'text',
          label: this.$t('quantity'),
          required: false,
          rules: 'integer',
          colSize: 4,
          readonly: !this.canEditPrice,
        },
      ]
    },

    extraActions() {
      const canPublish = canPublishToShop()
      const canUnPublish = canUnPublishFromShop(this.entity.supplier)
      return [
        {
          icon: 'EyeIcon',
          title: 'View in e-shop',
          href: `${process.env.VUE_APP_SHOP_URL}product/${this.entity.eshopSlug}`,
          variant: 'outline-secondary',
          renderCondition: {
            key: 'published',
            value: true,
          },
        },
        {
          endpoint: 'eshop/publish',
          icon: 'UploadCloudIcon',
          title: 'Publish to e-shop',
          renderCondition: {
            key: 'published',
            value: false,
          },
          authorityValidation: canPublish,
        },
        {
          // callback: 'updateEshop',
          endpoint: 'eshop/publish',
          icon: 'UploadCloudIcon',
          title: 'Update e-shop',
          renderCondition: {
            key: 'published',
            value: true,
          },
          authorityValidation: canPublish,
        },
        // {
        //   callback: 'updateStockPrice',
        //   endpoint: 'eshop/update-stock-price',
        //   icon: 'UploadCloudIcon',
        //   title: 'Update e-shop Stock and Price',
        //   variant: 'warning',
        //   renderCondition: {
        //     key: 'published',
        //     value: true,
        //   },
        //   authorityValidation: canPublish,
        // },
        {
          endpoint: 'eshop/change-product-status',
          icon: 'DownloadCloudIcon',
          title: 'Remove from e-shop',
          variant: 'danger',
          renderCondition: {
            key: 'published',
            value: true,
          },
          authorityValidation: canUnPublish,
        },
      ]
    },
  },
  watch: {
    attributeOptions: function () {
      this.attributeOptions.map((item) => {
        this.fetchSubAttributeList(item.id)
      })
    },
    entityProdCategory: function () {
      if (this.entity.category) {
        // console.log('Entering into entityCategory():', this.entity.category)
        if (this.initialLoadCategory !== true) {
          this.entity.subCategory = ''
          Object.entries(this.entity).forEach((item) => {
            if (!isNaN(parseInt(item[0]))) {
              delete this.entity[item[0]]
            }
          })
        }
        this.subCategoryOptions = []
        let categoryId = this.entity.category.id
        if (categoryId != '') {
          axios
            .get('categories/list/all-sub-categories', {
              params: { categoryId },
            })
            .then((response) => {
              this.subCategoryOptions = response.data
              if (this.subCategoryOptions.length == 0)
                this.attributeOptions = this.subAttributeOptions = []
            })
        }
        this.initialLoadCategory = false
      } else {
        // console.log('else block entityProdCategory()')
        this.entity.subCategory = ''
        this.subCategoryOptions = this.attributeOptions = []
      }
    },
    entityProdSubCategory: function () {
      if (this.entity.subCategory) {
        // console.log(
        //   'Entering into entitySubCategory():',
        //   this.entity.subCategory
        // )
        if (this.initialLoadSubCategory !== true) {
          Object.entries(this.entity).forEach((item) => {
            if (!isNaN(parseInt(item[0]))) {
              delete this.entity[item[0]]
            }
          })
        }
        this.attributeOptions = []
        let categoryId = this.entity.subCategory.id
        if (categoryId != '') {
          axios
            .get('categories/list/attributes', {
              params: { categoryId },
            })
            .then((response) => {
              this.attributeOptions = response.data
              if (this.attributeOptions.length == 0)
                this.subAttributeOptions = []
            })
        }
        this.initialLoadSubCategory = false
      } else {
        // console.log('else block entityProdSubCategory()')
        this.attributeOptions = this.subAttributeOptions = []
      }
    },
  },
  mounted() {
    // this.$store.dispatch('app-common/fetchCategories').then((response) => {
    //   this.categoryOptions = response.data
    // })
    this.$store.dispatch('app-common/fetchSellers').then((response) => {
      this.sellerOptions = response.data
    })
    this.$store.dispatch('app-common/fetchSuppliers').then((response) => {
      this.supplierOptions = response.data
    })
    this.$store.dispatch('app-common/fetchTags').then((response) => {
      this.tagsOptions = response.data
    })
    this.$store.dispatch('app-common/fetchShippingClasses').then((response) => {
      this.shippingClassOptions = response.data
    })
    this.$store.dispatch('app-common/fetchColorOptions').then((response) => {
      this.colorOptions = response.data
    })
    axios.get('categories/list/all-categories').then((response) => {
      this.categoryListOptions = response.data
    })
  },

  methods: {
    extraValidation() {
      if (
        this.entity.minPrice > 0 &&
        this.entity.maxPrice > 0 &&
        this.entity.minPrice > this.entity.maxPrice && false
      ) {
        return this.$t('min price cannot be larger than max')
      }
      return ''
    },
    fetchSubAttributeList(id) {
      axios
        .get('categories/list/sub-attributes', {
          params: { attributeId: id },
        })
        .then((response) => {
          this.subAttributeOptions[id] = response.data
          this.subAttributeOptions = { ...this.subAttributeOptions }
        })
    },
  },
}
</script>
<style scoped>
.section-image img {
  width: 40px;
  height: auto;
}
</style>
