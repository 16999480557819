<template>
  <b-card>
    <div v-if="title" class="d-flex">
      <h1 class="flex-grow-1" :class="{ 'required-section': requiredSection }">
        {{ title }}
      </h1>

      <div class="section-image">
        <img
          v-if="isValidSection"
          src="@/assets/images/icons/section-valid.png"
        />
        <img v-else src="@/assets/images/icons/section-invalid.png" />
      </div>
    </div>

    <b-row align-h="center">
      <template v-if="useMultiGrid">
        <b-col
          v-for="(columnFields, index) in formFields"
          :key="index"
          :md="
            multiGridColumns[index]
              ? multiGridColumns[index]
              : 12 / formFields.length
          "
          :cols="12"
        >
          <b-row>
            <b-col
              v-for="formField in columnFields"
              :key="formField.name ? formField.name : formField.id"
              class="mt-1"
              cols="12"
              :md="formField.colSize ? formField.colSize : 4"
            >
              <FormField
                v-if="!formField.hidden"
                :form-field="formField"
                :module="module"
                :entity="formField.parent ? entity[formField.parent] : entity"
                :sync="validateSection"
              />
            </b-col>
          </b-row>
        </b-col>
      </template>
      <template v-else>
        <b-col
          v-for="formField in formFields"
          :key="formField.name ? formField.name : formField.id"
          class="mt-1"
          cols="12"
          :md="formField.colSize ? formField.colSize : 4"
        >
          <FormField
            v-if="!formField.hidden"
            :form-field="formField"
            :module="module"
            :entity="formField.parent ? entity[formField.parent] : entity"
            :sync="validateSection"
          />
        </b-col>
      </template>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BAvatar } from 'bootstrap-vue'
import FormField from '@/components/ProductLayout/ProductLayoutFormField.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    FormField,
  },
  props: {
    module: {
      type: String,
      required: true,
    },
    formFields: {
      type: Array,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
      required: false,
    },
    requiredSection: {
      type: Boolean,
      default: false,
      required: false,
    },
    useMultiGrid: {
      type: Boolean,
      default: false,
      required: false,
    },
    multiGridColumns: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      blankEntity: {},
      init: false,
      isValidSection: null,
    }
  },
  mounted() {
    this.initBlankEntity()

    this.$store.commit('app-common/entity', {
      type: this.module,
      item: JSON.parse(JSON.stringify(this.entity)),
    })

    this.validateSection()
  },
  methods: {
    validateSection() {
      let isValid = true

      const validate = (type, value) => {
        if (isValid) {
          switch (type) {
            case 'quill':
            case 'gallery':
              isValid = value && value.length
              break
            default:
              isValid = value
          }
        }
      }

      if (this.useMultiGrid) {
        Object.entries(this.formFields).forEach(([index, column]) => {
          column.forEach((field) => {
            validate(field.type, this.entity[field.id])
          })
        })
      } else {
        Object.entries(this.formFields).forEach(([index, field]) => {
          validate(field.type, this.entity[field.id])
        })
      }

      this.isValidSection = isValid
    },

    initBlankEntity() {
      if (!('id' in this.entity)) {
        this.formFields.forEach((field) => {
          let newVal = ''
          if (field.type === 'select' || field.type === 'selectmultiple') {
            newVal = null
          } else if (
            field.type === 'checkbox' ||
            field.type === 'checkboxgroup'
          ) {
            newVal = false
          }
          if (field.parent) {
            if (!(field.parent in this.blankEntity)) {
              this.blankEntity[field.parent] = {}
            }
            this.blankEntity[field.parent][field.id] = newVal
          } else {
            this.blankEntity[field.id] = newVal
          }
        })

        this.$parent.entity = {
          ...this.$parent.entity,
          ...this.entity,
          ...this.blankEntity,
        }
      }

      this.init = true
    },
  },
}
</script>

<style scoped>
.required-section:after {
  content: '*';
  display: inline-block;
  color: red;
}

.section-image img {
  width: 40px;
  height: auto;
}
</style>
